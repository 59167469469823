import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card shadow mb-4" }
const _hoisted_2 = { class: "card-body p-5" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "form-group mt-2" }
const _hoisted_5 = { class: "form-group mt-2" }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "card-header py-3" }, [
      _createElementVNode("h6", { class: "center-text font-weight-bold" }, " Add new image ")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("h4", { class: "text-gray-900 mb-4" }, " New image ", -1)),
        _createElementVNode("form", {
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent","stop"]))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              placeholder: "Title",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.image.title) = $event))
            }, null, 512), [
              [
                _vModelText,
                _ctx.image.title,
                void 0,
                { trim: true }
              ]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              placeholder: "Description",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.image.description) = $event))
            }, null, 512), [
              [
                _vModelText,
                _ctx.image.description,
                void 0,
                { trim: true }
              ]
            ])
          ]),
          _createElementVNode("input", {
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleFileUpload($event))),
            type: "file",
            class: "mt-2 form-control",
            ref: "fileInput",
            accept: ".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
          }, null, 544),
          _createElementVNode("button", {
            disabled: _ctx.isSubmitDisabled,
            class: "mt-2 btn btn-primary",
            type: "submit"
          }, _toDisplayString(_ctx.buttonText), 9, _hoisted_6)
        ], 32)
      ])
    ])
  ]))
}