import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vShow as _vShow, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "card shadow-lg mt-5" }
const _hoisted_3 = { class: "card-body p-0 p-5 text-center" }
const _hoisted_4 = { class: "form-group mt-2" }
const _hoisted_5 = { class: "form-group mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-header py-3" }, [
        _createElementVNode("h6", { class: "center-text font-weight-bold" }, " Login ")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", {
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent","stop"]))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              placeholder: "Username",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event))
            }, null, 512), [
              [
                _vModelText,
                _ctx.username,
                void 0,
                { trim: true }
              ]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              type: "password",
              class: "form-control",
              placeholder: "Password",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event))
            }, null, 512), [
              [
                _vModelText,
                _ctx.password,
                void 0,
                { trim: true }
              ]
            ])
          ]),
          _withDirectives(_createElementVNode("div", { class: "text-negative row justify-center text-h6" }, _toDisplayString(_ctx.error), 513), [
            [_vShow, _ctx.error.length != 0]
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("button", {
            class: "mt-2 btn btn-primary btn-block",
            type: "submit"
          }, " Login ", -1))
        ], 32)
      ])
    ])
  ]))
}