import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card shadow mb-4" }
const _hoisted_2 = { class: "card-header d-flex justify-content-between align-items-center" }
const _hoisted_3 = { class: "center-text font-weight-bold" }
const _hoisted_4 = { class: "float-right" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", _hoisted_3, _toDisplayString(_ctx.image.title), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("a", {
          class: "btn btn-light",
          role: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClipboard(_ctx.image.links.orig)))
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("i", {
            class: "fas fa-clipboard",
            "aria-hidden": "true"
          }, null, -1)
        ])),
        _createElementVNode("a", {
          class: "btn btn-light",
          role: "button",
          target: "_blank",
          rel: "nofollow",
          href: _ctx.image.links.orig
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("i", { class: "fas fa-download" }, null, -1)
        ]), 8, _hoisted_5),
        _createElementVNode("a", {
          class: "btn btn-light",
          role: "button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.deleteImage && _ctx.deleteImage(...args)))
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("i", { class: "fas fa-trash-alt" }, null, -1)
        ]))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("img", {
          class: "img-fluid px-3 px-sm-4",
          style: {"width":"25rem"},
          src: _ctx.image.links.previews.medium,
          alt: ""
        }, null, 8, _hoisted_8),
        _createElementVNode("div", null, [
          _withDirectives(_createElementVNode("p", null, _toDisplayString(_ctx.image.description), 513), [
            [_vShow, _ctx.image.description.length > 0]
          ])
        ])
      ])
    ])
  ]))
}