import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent","stop"])),
    class: "d-inline-block mx-auto my-2 navbar-form",
    id: "search",
    role: "search"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        class: "form-control mr-sm-1",
        placeholder: "Search for...",
        type: "search",
        "aria-label": "Search",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event))
      }, null, 512), [
        [
          _vModelText,
          _ctx.searchText,
          void 0,
          { trim: true }
        ]
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "input-group-append" }, [
        _createElementVNode("button", {
          class: "btn btn-primary",
          type: "submit"
        }, [
          _createElementVNode("i", { class: "fas fa-search fa-sm" })
        ])
      ], -1))
    ])
  ], 32))
}