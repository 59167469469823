import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sticky-footer bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "container my-auto" }, [
      _createElementVNode("div", { class: "copyright text-center my-auto" }, [
        _createElementVNode("span", null, "Copyright © isthisloss 2023")
      ])
    ], -1)
  ])))
}