import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-lg-4 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_NewImage = _resolveComponent("NewImage")!
  const _component_ImageCard = _resolveComponent("ImageCard")!
  const _component_PageFooter = _resolveComponent("PageFooter")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PageHeader, { onSearchSubmit: _ctx.searchImages }, null, 8, ["onSearchSubmit"]),
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "text-center my-4 text-gray-800" }, " Images ", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_NewImage, { onSubmit: _ctx.createImage }, null, 8, ["onSubmit"])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
          return (_openBlock(), _createElementBlock("div", {
            key: image.id,
            class: "col-lg-4 mb-4"
          }, [
            _createVNode(_component_ImageCard, {
              image: image,
              onDeleteImage: _ctx.deleteImage
            }, null, 8, ["image", "onDeleteImage"])
          ]))
        }), 128))
      ])
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { id: "page-bottom" }, null, -1)),
    _createVNode(_component_PageFooter)
  ], 512)), [
    [_vShow, _ctx.loaded]
  ])
}